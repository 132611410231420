import React, { useEffect, useState } from "react"
import ScheduleDateTimePicker from "../../../core/components/ScheduleDateTimePicker/ScheduleDateTimePicker"
import dayjs from "dayjs"

export interface CategoryDateTimeSchedulerProps {
    onSelect: (dateRange: [Date | null, Date | null], uploadTime: string, removeTime: string) => void
    remove?: boolean
}

const CategoryDateTimeScheduler: React.FC<CategoryDateTimeSchedulerProps> = ({ onSelect, remove }) => {
    const [isCalendarOpened, setIsCalendarOpened] = useState(false)

    const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([null, null])

    const [uploadingTimeSlot, setUploadingTimeSlot] = useState("")

    const [removingTimeSlot, setRemovingTimeSlot] = useState("")

    const [displaySelectedDateTimeRange, setDisplaySelectedDateTimeRange] = useState("")

    const dateTimeSelectionHandler = () => {
        const uploadingDate = dayjs(selectedDateRange[0]).format("DD/MM/YYYY")
        let removingDate

        if (selectedDateRange[1]) {
            removingDate = dayjs(selectedDateRange[1]).format("DD/MM/YYYY")
        } else {
            removingDate = uploadingDate
        }

        if (selectedDateRange[1] && uploadingTimeSlot && removingTimeSlot) {
            setDisplaySelectedDateTimeRange(`${uploadingDate} ${uploadingTimeSlot} - ${removingDate} ${removingTimeSlot}`)
            onSelect(selectedDateRange, uploadingTimeSlot, removingTimeSlot)
            setIsCalendarOpened(false)
        }
    }

    const dateTimeResetHandler = (e: React.MouseEvent<HTMLButtonElement> | any) => {
        setSelectedDateRange([null, null])
        setUploadingTimeSlot("")
        setRemovingTimeSlot("")
        setDisplaySelectedDateTimeRange("")
        setIsCalendarOpened(false)
    }

    useEffect(() => {
        dateTimeResetHandler(remove)
    }, [remove])

    return (
        <div>
            <ScheduleDateTimePicker
                isCalendarOpened={isCalendarOpened}
                setIsCalendarOpened={setIsCalendarOpened}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                uploadingTimeSlot={uploadingTimeSlot}
                setUploadingTimeSlot={setUploadingTimeSlot}
                removingTimeSlot={removingTimeSlot}
                setRemovingTimeSlot={setRemovingTimeSlot}
                dateTimeSelectionHandler={dateTimeSelectionHandler}
                dateTimeResetHandler={dateTimeResetHandler}
                popoverTrigger={
                    <button onClick={() => setIsCalendarOpened((prevState) => !prevState)}
                            className={"px-3 py-2 flex items-center justify-between bg-white border border-minotaurus-brown rounded-[4.69px]"}>
                        {displaySelectedDateTimeRange ? <div className={"pr-3"}>{displaySelectedDateTimeRange}</div> :
                            <div className={"text-left min-w-[200px]"}>-</div>}
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.5 9C2.5 7.11438 2.5 6.17157 3.08579 5.58579C3.67157 5 4.61438 5 6.5 5H13.5C15.3856 5 16.3284 5 16.9142 5.58579C17.5 6.17157 17.5 7.11438 17.5 9V9.16667H2.5V9Z"
                                fill="#001C33" />
                            <rect x="2.5" y="5" width="15" height="12.5" rx="2" stroke="#001C33" strokeWidth="1.2" />
                            <path d="M2.5 9.16663H17.5" stroke="#001C33" strokeWidth="1.2" strokeLinecap="round" />
                            <path d="M7.5 13.3334H12.5" stroke="#001C33" strokeWidth="1.2" strokeLinecap="round" />
                            <path d="M6.66663 2.5L6.66663 5.83333" stroke="#001C33" strokeWidth="1.2"
                                  strokeLinecap="round" />
                            <path d="M13.3334 2.5L13.3334 5.83333" stroke="#001C33" strokeWidth="1.2"
                                  strokeLinecap="round" />
                        </svg>
                    </button>
                } />
        </div>
    )
}

export default CategoryDateTimeScheduler