import React, { useEffect, useState } from "react"
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { A11y, Mousewheel } from "swiper"

import "swiper/css"
import "swiper/css/a11y"
import "swiper/css/mousewheel"
import { Banner, BannerPlace, Category } from "../../../types"
import { BookmarkIcon, BookmarkAltIcon, XIcon } from "@heroicons/react/outline"
import toast from "react-hot-toast"
import BannerPlaceModal from "../../../core/components/BannerPlaceModal/BannerPlaceModal"
import SchedulerInputModal from "../../../core/components/BannerPlaceModal/SchedulerInputModal"
import { getAllCategories } from "../../../data/categories.data"

interface PlaceSwiperProps {
    places: BannerPlace[]
    sendToEditPlacePage: (place: BannerPlace) => void
    deleteBannerPlaceHandler: (page: string, place: string) => void
    placeState: boolean
    placeStateSlug: string
    bannerData: Banner

    addBannerToBannerPlaceHandler: (
        slug: string,
        bannerPlaceSlugs: string[],
        page: string,
        place: string
    ) => void
}

const emptyBannerPlace: BannerPlace = {
    slugs: [],
    place: "",
    page: ""
}


const PlaceSwiper = ({
                         places,
                         sendToEditPlacePage,
                         deleteBannerPlaceHandler,
                         placeState,
                         placeStateSlug,
                         addBannerToBannerPlaceHandler,
                         bannerData
                     }: PlaceSwiperProps) => {
    const swiperParameters: SwiperProps = {
        modules: [A11y, Mousewheel],
        centeredSlides: true,
        mousewheel: true,
        initialSlide: 1,
        slidesPerView: 3,
        breakpoints: {
            0: { slidesPerView: 1, spaceBetween: 8 },
            768: { slidesPerView: 1, spaceBetween: 16 },
            1024: {
                direction: "horizontal",
                grid: { rows: 1 },
                slidesPerView: 2,
                slidesPerGroup: 1,
                centeredSlides: false,
                spaceBetween: 16,
                initialSlide: 0,
                rewind: false,
                speed: 300,
                navigation: {
                    enabled: false,
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                    hideOnClick: false
                },
                pagination: {
                    enabled: false,
                    el: ".swiper-pagination",
                    hideOnClick: true,
                    type: "bullets",
                    clickable: false,
                    progressbarOpposite: false,
                    dynamicBullets: false,
                    dynamicMainBullets: 1
                },
                scrollbar: {
                    enabled: false,
                    el: ".swiper-scrollbar",
                    draggable: false,
                    hide: true,
                    snapOnRelease: false
                },
                freeMode: {
                    enabled: false,
                    sticky: false,
                    momentum: true,
                    momentumBounce: true,
                    momentumBounceRatio: 1,
                    momentumRatio: 1,
                    momentumVelocityRatio: 1
                },
                zoom: { minRatio: 1, maxRatio: 3 },
                simulateTouch: true,
                allowSlidePrev: true,
                allowSlideNext: true,
                allowTouchMove: true,
                followFinger: true,
                longSwipes: true,
                shortSwipes: true,
                resistance: true,
                resistanceRatio: 0.85,
                threshold: 0,
                touchRatio: 1
            },
            1280: { slidesPerView: 3, centeredSlides: true, spaceBetween: 24 },
            1920: {
                direction: "horizontal",
                grid: { rows: 1 },
                slidesPerGroup: 1,
                spaceBetween: 24,
                initialSlide: 0,
                rewind: false,
                speed: 300,
                navigation: {
                    enabled: false,
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                    hideOnClick: false
                },
                pagination: {
                    enabled: false,
                    el: ".swiper-pagination",
                    hideOnClick: true,
                    type: "bullets",
                    clickable: false,
                    progressbarOpposite: false,
                    dynamicBullets: false,
                    dynamicMainBullets: 1
                },
                scrollbar: {
                    enabled: false,
                    el: ".swiper-scrollbar",
                    draggable: false,
                    hide: true,
                    snapOnRelease: false
                },
                freeMode: {
                    enabled: false,
                    sticky: false,
                    momentum: true,
                    momentumBounce: true,
                    momentumBounceRatio: 1,
                    momentumRatio: 1,
                    momentumVelocityRatio: 1
                },
                zoom: { minRatio: 1, maxRatio: 3 },
                simulateTouch: true,
                allowSlidePrev: true,
                allowSlideNext: true,
                allowTouchMove: true,
                followFinger: true,
                longSwipes: true,
                shortSwipes: true,
                resistance: true,
                resistanceRatio: 0.85,
                threshold: 0,
                touchRatio: 1
            }
        }
    }

    const [categories, setCategories] = useState<Category[]>([])
    const [isBannerPlaceModalOpen, setIsBannerPlaceModalOpen] = useState(false)
    const [isBannerSchedulerModalOpen, setIsBannerSchedulerModalOpen] = useState(false)
    const [selectedBannerPlace, setSelectedBannerPlace] = useState<BannerPlace>(emptyBannerPlace)

    useEffect(() => {
        if (places.length > 0) {
            getAllCategories().then((res) => {
                setCategories(res)
            })
        }
    }, [places])

    const getCategoryNameFromSlug = (slug: string) => {
        if (slug === "index") {
            return "Home Page"
        }

        const category = categories.find((category) => category.slug === slug)

        if (category) {
            return category.name
        } else {
            return slug
        }

    }

    return (
        <Swiper {...swiperParameters} className={`banner-place-swiper`}>
            {places.map((singleBannerPlace: BannerPlace) => (
                <SwiperSlide
                    key={`${singleBannerPlace.place}-${singleBannerPlace.page}`}
                >
                    <div className="overflow-hidden rounded-lg bg-white shadow hover:shadow-md p-2 m-2 w-full">
                        <div className="p-5">
                            <div className={`absolute right-5 top-5`}>
                                <XIcon
                                    className={`h-6 w-6 text-red-500 cursor-pointer`}
                                    onClick={() => {
                                        deleteBannerPlaceHandler(
                                            singleBannerPlace.page,
                                            singleBannerPlace.place
                                        )
                                        toast.success(
                                            `Deleted ${singleBannerPlace.place} from ${singleBannerPlace.page} Successfully`
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0 text-gray-500">
                                    {singleBannerPlace.slugs.length === 0 ? (
                                        <BookmarkAltIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <BookmarkIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {singleBannerPlace.place[0].toUpperCase() +
                                                singleBannerPlace.place.slice(1)}{" "}
                                            on{" "}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {getCategoryNameFromSlug(singleBannerPlace.page)}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        {placeState ? (
                            <>
                                <BannerPlaceModal
                                    isOpened={isBannerPlaceModalOpen}
                                    setIsOpened={setIsBannerPlaceModalOpen}
                                    ActivateNowHandler={() => {
                                        addBannerToBannerPlaceHandler(
                                            placeStateSlug,
                                            selectedBannerPlace.slugs,
                                            selectedBannerPlace.page,
                                            selectedBannerPlace.place
                                        )
                                        setIsBannerPlaceModalOpen(false)
                                    }}
                                    scheduleHandler={() => {
                                        setIsBannerPlaceModalOpen(false)
                                        setIsBannerSchedulerModalOpen(true)
                                    }
                                    }
                                    category={selectedBannerPlace.page}
                                    image={bannerData.mobileThumbnail}
                                />
                                <SchedulerInputModal image={bannerData.mobileThumbnail}
                                                     isOpened={isBannerSchedulerModalOpen}
                                                     setIsOpened={setIsBannerSchedulerModalOpen}
                                                     bannerPlace={singleBannerPlace.place} bannerSlug={bannerData.slug}
                                                     categorySlug={selectedBannerPlace.page} />
                                <div
                                    onClick={() => {
                                        setIsBannerPlaceModalOpen(true)
                                        setSelectedBannerPlace(singleBannerPlace)
                                    }}
                                    className="bg-gray-50 px-5 py-3 cursor-pointer hover:bg-gray-100"
                                >
                                    <div className="text-sm">
                                        <div className="font-medium text-cyan-700 hover:text-cyan-900">
                                            Add Banner Here
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    onClick={() => {
                                        sendToEditPlacePage(singleBannerPlace)
                                    }}
                                    className="bg-gray-50 px-5 py-3 cursor-pointer hover:bg-gray-100"
                                >
                                    <div className="text-sm">
                                        <div className="font-medium text-cyan-700 hover:text-cyan-900">
                                            View all Banners ({singleBannerPlace.slugs.length})
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default PlaceSwiper
