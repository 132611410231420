import { BrowserRouter, Route, Routes } from "react-router-dom"
import CategoriesPage from "./modules/categories/pages"
import Dashboard from "./modules/dashboard/pages"
import StoresPage from "./modules/stores/pages"
import { Provider } from 'react-redux';
import { Toaster } from "react-hot-toast"
import AddStore from "./modules/stores/pages/AddStore"
// import ReOrderStores from "./modules/stores/pages/ReOrderStores";
import EditStore from "./modules/stores/pages/EditStore"
import AddCategory from "./modules/categories/pages/AddCategory"
import EditCategory from "./modules/categories/pages/EditCategory"
import ReOrderCategories from "./modules/categories/pages/ReOrderCategories"
import SingleStorePage from "./modules/stores/pages/SingleStorePage"
import SingleCategoryPage from "./modules/categories/pages/SingleCategoryPage"
import LoginPage from "./modules/auth/LoginPage"
import AllPressPage from "./modules/press/pages"
import AddPress from "./modules/press/pages/AddPress"
import ListBanners from "./modules/banners/pages"
import AddBanner from "./modules/banners/pages/AddBanner"
import EditBanner from "./modules/banners/pages/EditBanner"
import AddPlace from "./modules/banners/pages/AddPlace"
import EditPlace from "./modules/banners/pages/EditPlace"
import ListKeywords from "./modules/keywords/pages/ListKeywords"
import ListLocations from "./modules/stores/pages/ListLocations"
import AddLocations from "./modules/stores/pages/AddLocations"
import EditLocations from "./modules/stores/pages/EditLocations"
import { store } from "./store"

const App = () => {
  return (
    <Provider store={store}>
    <BrowserRouter>
      <Toaster />
      <>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/stores" element={<StoresPage />} />
          <Route path="/stores/:slug" element={<SingleStorePage />} />
          <Route path="/stores/add" element={<AddStore />} />
          <Route path="/stores/edit/:slug" element={<EditStore />} />
          <Route path="/stores/edit/:slug/locations" element={<ListLocations />} />
          <Route path="/stores/edit/:slug/locations/add" element={<AddLocations />} />
          <Route path="/stores/edit/:slug/locations/edit/:id" element={<EditLocations/>}/>

          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/categories/:slug" element={<SingleCategoryPage />} />
          <Route path="/categories/add" element={<AddCategory />} />
          <Route path="/categories/edit/:slug" element={<EditCategory />} />
          <Route path="/categories/reorder" element={<ReOrderCategories />} />

          <Route path="/press" element={<AllPressPage />} />
          <Route path="/press/add" element={<AddPress />} />

          <Route path="banners" element={<ListBanners />} />
          <Route path="banners/add" element={<AddBanner />} />
          <Route path="banners/edit/:slug" element={<EditBanner />} />
          <Route path="banners/add-place" element={<AddPlace />} />
          <Route path="banners/edit-place/:page/:place" element={<EditPlace />} />

          <Route path="keywords" element={<ListKeywords />} />
        </Routes>
      </>
    </BrowserRouter>
    </Provider>
  )
}

export default App
