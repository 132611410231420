import axios from "../utils/axiosNew"

const createABannerPlace = async (page: string, place: string) => {
    return axios.post("/admin/api/banner/make-place", {
        page,
        place
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }

        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const getStoreBannerPlaces = async () => {
    return axios.get("/admin/api/banner/all")
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}


const getBannersOfAPlace = async (place: string, page: string) => {
    return axios.get(`/admin/api/Banner/category`, {
        params: {
            place,
            categorySlug: page
        }
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const removeBannerFromPlace = async (place: string, page: string, bannerSlug: string) => {
    return axios.post(`/admin/api/banner/remove-banner`, {
        place,
        page,
        slug: bannerSlug
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const reorderOrChangeBannerPlace = async (place: string, page: string, slugs: string[]) => {
    return axios.post(`/admin/api/banner/reorder`, {
        place,
        page,
        slugs
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const placeBanner = async (place: string, page: string, bannerSlug: string) => {
    return axios.post(`/admin/api/banner/place`, {
        page,
        place,
        slug: bannerSlug
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const clearBannerDetails = async (bannerSlug: string) => {
    return axios.post(`/admin/api/banner/clear-values`, {
        slug: bannerSlug
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const deleteBannerPlace = async (page: string, place: string) => {
    return axios.post(`/admin/api/banner/remove-place`, {
        page,
        place
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

const deleteScheduledBanner = async (page: string, place: string, slug: string) => {
    return axios.delete(`/admin/api/banner`, {
        params: {
            page,
            place,
            slug
        }
    })
        .then((res) => {
            return {
                data: res.data,
                success: true
            }
        })
        .catch((err) => {
            return {
                data: err.response.data,
                success: false
            }
        })
}

export {
    createABannerPlace,
    getStoreBannerPlaces,
    getBannersOfAPlace,
    removeBannerFromPlace,
    reorderOrChangeBannerPlace,
    placeBanner,
    clearBannerDetails,
    deleteBannerPlace,
    deleteScheduledBanner
}