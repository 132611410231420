/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Switch } from "@headlessui/react"
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    PencilAltIcon
} from "@heroicons/react/outline"

import classNames from "classnames"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useParams } from "react-router-dom"
import {
    assignCategory,
    getCategoriesByStore,
    removeCategory
} from "../../../data/categories.data"

import {
    createKeyword,
    createKeywordStores,
    editStore,
    getAllKeywords,
    getKeywordStoresBySlug,
    getStore,
    getStoreByIndex,
    removeKeywordStore,
    slugifyWord,
    uploadImage
} from "../../../data/stores.data"
import { Category, Location, Store } from "../../../types"
import { AssignCategory } from "../components/AssignCategory"
import CoverImageDropZone from "../components/CoverImageDropZone"
import { InputEdit } from "../components/InputEdit"
import LogoDropZone from "../components/LogoDropZone"
import { motion } from "framer-motion"
import Select from "../../../core/components/Select"
import withProtected from "../../../core/hoc/withProtected"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import Keywords from "../components/Keywords"
import KeywordPill from "../components/KeywordPill"
import PrimaryButton from "../../../core/components/Button"
import { autoGenerateKeywords } from "../../../data/keywords_data"
import { AddSubStore } from "../../../data/location_data"
import AddLocationStore from "../components/AddLocationStore"
import PillCloseIcon from "../../../icons/PillClose.icon"
import AddNewLocationForm, { emptyCity } from "../components/AddNewLocationForm"
import { AddLocationsStore, EditLocations, getLocations } from "../../../data/locations_data"
import { Simulate } from "react-dom/test-utils"
import error = Simulate.error
import Skeleton from "react-loading-skeleton"
import { getBetweenStores } from "../../../data/store_list_data"

interface Editable<T> {
    editable: boolean
    value: T
    url?: string | null
}

interface subStore {
    id: string
    nameOfPlace: string
    address: string
    addressUrl: string
}

const availability = [
    { id: 1, label: "In-Store only", value: "INS" },
    { id: 2, label: "Online only", value: "ONL" },
    { id: 3, label: "In-Store & Online", value: "BTH" },
    { id: 4, label: "None", value: null }
]

// export const locationsList:Location[] = [
//   {
//     id:1,
//     storeSlug: 'Nolimit',
//     nameOfPlace: 'Wella',
//     addressLine1: 'Nolimit, Galle Rd, Colombo 06',
//     addressLine2: 'Nolimit, Galle Rd, Colombo 06',
//     city:emptyCity,
//     mapUrl: 'https://www.google.com/maps/dir//Galle+Rd,+Colombo+00600/@6.8945118,79.8514573,13.61z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ae25bb886fccd7d:0xdaf162cd8e86d1bc!2m2!1d79.8602518!2d6.8767651?entry=ttu',
//     isActive: true,
//     createdAt:'',
//     updatedAt:'',
//   },
//   {
//     id:1,
//     storeSlug: 'Nolimit',
//     nameOfPlace: 'Dehiwala',
//     addressLine1: 'Nolimit, Galle Rd, Colombo 06',
//     addressLine2: 'Nolimit, Galle Rd, Colombo 06',
//     city:emptyCity,
//     mapUrl: 'https://www.google.com/maps/dir//17+Hill+St,+Dehiwala-Mount+Lavinia+10350/@6.8514187,79.7841979,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ae25b068439cecb:0xd21d5cc0c213530e!2m2!1d79.8666053!2d6.851393?entry=ttu',
//     isActive: true,
//     createdAt:'',
//     updatedAt:'',
//   },
//   {
//     id:1,
//     storeSlug: 'Nolimit',
//     nameOfPlace: 'Dehiwala',
//     addressLine1: 'Nolimit, Galle Rd, Colombo 06',
//     addressLine2: 'Nolimit, Galle Rd, Colombo 06',
//     city:emptyCity,
//     mapUrl:  'https://www.google.com/maps?s=web&vet=12ahUKEwiQoYCzoJqHAxVPzDgGHdPNA6kQ5OUKegQIKxAN..i&cs=1&um=1&ie=UTF-8&fb=1&gl=lk&sa=X&geocode=KduzrYYmW-I6MVq11RIgM-lP&daddr=RVH9%2B2P6,+Galle+Rd,+Dehiwala-Mount+Lavinia',
//     isActive: true,
//     createdAt:'',
//     updatedAt:'',
//   }
// ]

const EditStore = () => {
    const [isLogoLight, setIsLogoLight] = useState(true)
    const [storeAvailability, setStoreAvailability] = useState<any>(null)
    const [assignedCategories, setAssignedCategories] = useState<Category[]>([])
    const [addedLocations, setAddedLocations] = useState<Location[]>([])
    const [locations, setLocations] = useState<Location[]>([])
    const [showAddNewLocationForm, setShowAddNewLocationForm] = useState(false)

    const [isActive, setIsActive] = useState(true)

    const [previousStore, setPreviousStore] = useState<Store | undefined>(
        undefined
    )
    const [nextStore, setNextStore] = useState<Store | undefined>(undefined)

    const [logo, setLogo] = useState<Editable<File | null>>({
        editable: false,
        value: null,
        url: null
    })

    const [cover, setCover] = useState<Editable<File | null>>({
        editable: false,
        value: null,
        url: null
    })
    const [coverWithLogo, setCoverWithLogo] = useState<Editable<File | null>>({
        editable: false,
        value: null,
        url: null
    })

    const [store, setStore] = useState<Store | null>(null)

    const [loading, setLoading] = useState(false)
    const [locationsLoading, setLocationsLoading] = useState(false)

    const params = useParams()

    function getExtension(path: string) {
        const segments = path.split(".")
        const lastIndex = segments.length - 1
        return segments[lastIndex]
    }

    const handleRemoveLocationForStore = async (location: Location) => {
        setLocations(prevLocations =>
            prevLocations.map(prevLocation =>
                location.id === prevLocation.id ? { ...location, isActive: false } : location
            )
        )
        handleLocationActive(location, false)

        // setAddedLocations(prevAddedLocations => prevAddedLocations.filter(location => location.id !== id));
    }

    const fetchLocationsFromApi = async () => {
        setLocationsLoading(true)
        const { slug } = params
        try {
            const storeLocations: Location[] = await getLocations(slug!)
            setLocations(storeLocations)
            const activeLocations = storeLocations.filter(location => location.isActive)
            setAddedLocations(activeLocations)
            setLocationsLoading(false)
        } catch (error) {
            console.error("Failed to fetch locations:", error)
            setLocationsLoading(false)
        }
    }


    useEffect(() => {
        fetchLocationsFromApi()
    }, [])


    const AddLocations = async (slug: string | undefined, location: Location, needToActivate: boolean) => {
        try {
            const activatedLocation = needToActivate ? { ...location, isActive: true } : location
            await AddLocationsStore(slug!, activatedLocation)
            await fetchLocationsFromApi()
            toast.success("Location Added Successfully!")
        } catch (error) {
            toast.error("Something went wrong!")
        }
    }

    const handleLocationActive = async (location: Location, activate: boolean) => {
        try {
            await EditLocations({ ...location, isActive: activate })
            await fetchLocationsFromApi()
            toast.success("Location status updated successfully!")
        } catch (error) {
            toast.error("Something went wrong!")
        }
    }


    const refetchAssignedCategories = async (slug: string) => {
        try {
            const response = await getCategoriesByStore(slug)
            if (response) {
                setAssignedCategories(response)
            }
        } catch (error: any) {
            toast.error(error["message"] ?? "Somthing went wrong!")
        }
        setLoading(false)
    }

    const unAssignCategory = async (storeSlug: string, categorySlug: string) => {
        const response = await removeCategory(storeSlug, categorySlug)

        if (response?.success) {
            toast.success(response.message ?? "Removed Successfully!")
            await refetchAssignedCategories(storeSlug)
        }

        if (response && !response?.success) {
            toast.error(response.message)
        }
    }

    const fetchStore = async (slug: string) => {
        getStore(slug!)
            .then(async (store) => {
                const index = store?.index!

                const betweenStores = await getBetweenStores(index)

                if (betweenStores.data.storeBefore) {
                    setPreviousStore(betweenStores.data.storeBefore)
                } else {
                    setPreviousStore(undefined)
                }

                if (betweenStores.data.storeAfter) {
                    setNextStore(betweenStores.data.storeAfter)
                } else {
                    setNextStore(undefined)
                }

                setStore(store!)
                setIsLogoLight(store?.isLogoLight!)
                setIsActive(store?.active! ? true : false)
                const _storeAvailability = availability.find(
                    (item) => item.label === store?.storeAvailability
                )
                setStoreAvailability(_storeAvailability ?? availability[3])
                setCover({
                    ...cover,
                    editable: false,
                    url: store?.cover ?? null
                })
                setCoverWithLogo({
                    ...cover,
                    editable: false,
                    url: store?.coverWithLogo ?? null
                })
                setLogo({
                    ...logo,
                    editable: false,
                    url: store?.logo ?? null
                })
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const updateStore = async (
        fieldName: keyof Store,
        value: any
    ): Promise<boolean> => {
        setLoading(true)
        const response = await editStore(store?.slug!, {
            [fieldName]: value
        })
        setLoading(false)

        if (response.status === 200 && response.data.success) {
            toast.success(response.data.message)

            fetchStore(store?.slug!)
            return true
        } else {
            toast.error(response.data.message ?? "Something went wrong")
            fetchStore(store?.slug!)
            return false
        }
    }

    const [keywordsLoading, setKeywordsLoading] = useState<boolean>(true)

    const [trigger, setTrigger] = useState<boolean>(false)
    useEffect(() => {
        const { slug } = params

        fetchStore(slug!).then((r) => {
            //  fetch Keywords
            getKeywordStoresBySlug(slug!).then((response: string[]) => {
                if (response) {
                    setKeywords(
                        response.map((keyword) => ({
                            keyword: keyword,
                            isActive: true,
                            count: 1,
                            groupedKeywords: []
                        }))
                    )
                    setKeywordsLoading(false)
                }
            })
        })
    }, [params, trigger])

    async function logoUpload() {
        if (!logo.value) {
            return toast.error("Select file before update!")
        }

        const uploadItem = {
            key: `stores/${store?.slug}/logo-${Date.now()}.${getExtension(
                logo.value.name
            )}`,
            file: logo.value
        }

        setLoading(true)

        // upload requests list
        await uploadImage(uploadItem)

        const success = await updateStore("logo", uploadItem.key)

        if (success) {
            await fetchStore(store?.slug!)
            setLoading(false)
            setLogo({
                editable: false,
                value: null,
                url: store?.logo
            })
        }
    }

    async function coverUpload() {
        if (!cover.value) {
            return toast.error("Select file before update!")
        }

        const uploadItem = {
            key: `stores/${store?.slug}/cover-${Date.now()}.${getExtension(
                cover.value.name
            )}`,
            file: cover.value
        }

        setLoading(true)

        // upload requests list
        await uploadImage(uploadItem)

        const success = await updateStore("cover", uploadItem.key)

        if (success) {
            await fetchStore(store?.slug!)
            setLoading(false)
            setCover({
                editable: false,
                value: null,
                url: store?.cover
            })
        }
    }

    async function uploadCoverWithLogo() {
        if (!coverWithLogo.value) {
            return toast.error("Select file before update!")
        }

        const uploadItem = {
            key: `stores/${store?.slug}/cover-with-logo-${Date.now()}.${getExtension(
                coverWithLogo.value.name
            )}`,
            file: coverWithLogo.value
        }

        setLoading(true)

        // upload requests list
        await uploadImage(uploadItem)

        const success = await updateStore("coverWithLogo", uploadItem.key)

        if (success) {
            await fetchStore(store?.slug!)
            setLoading(false)
            setCoverWithLogo({
                editable: false,
                value: null,
                url: store?.cover
            })
        }
    }

    interface subKeyword {
        keyword: string
        isActive: boolean
    }

    interface keyword {
        keyword: string
        isActive: boolean
        count: number
        groupedKeywords: subKeyword[]
        notFound?: boolean
    }

    const [keywords, setKeywords] = useState<keyword[]>([])
    const [newKeywordSubmitterLoading, setNewKeywordSubmitterLoading] =
        useState<boolean>(false)
    const handleKeywordsSelection = async (keywords: string[]) => {
        if (keywords.length > 0 && store) {
            setNewKeywordSubmitterLoading(true)

            const uniqueKeywords = keywords.filter(
                (item, pos, self) => self.indexOf(item) == pos
            )

            setNewKeywordSubmitterLoading(true)
            getAllKeywords()
                .then(async (response: any[]) => {
                    setNewKeywordSubmitterLoading(true)
                    setKeywordsLoading(true)

                    let keyword_promises: PromiseLike<any>[] = []

                    const keywordArray = response.map((item) => slugifyWord(item.slug))

                    const notFoundKeywords = uniqueKeywords
                        .filter((keyword) => !keywordArray.includes(slugifyWord(keyword)))
                        .map((keyword) => slugifyWord(keyword))

                    if (notFoundKeywords.length > 0) {
                        keyword_promises = notFoundKeywords.map((keyword) =>
                            createKeyword(slugifyWord(keyword), slugifyWord(keyword))
                        )
                    }

                    await Promise.all(keyword_promises)
                })
                .then(() => {
                    setNewKeywordSubmitterLoading(true)

                    const keyword_store_promises = uniqueKeywords.map((keyword) =>
                        createKeywordStores(
                            store.slug,
                            slugifyWord(keyword),
                            slugifyWord(keyword)
                        )
                    )

                    Promise.all(keyword_store_promises)
                        .then((r) => {
                            //  fetch Keywords
                            if (store) {
                                getKeywordStoresBySlug(store.slug).then(
                                    (response: string[]) => {
                                        if (response) {
                                            setKeywords(
                                                response.map((keyword) => ({
                                                    keyword: keyword,
                                                    isActive: true,
                                                    count: 1,
                                                    groupedKeywords: []
                                                }))
                                            )
                                            setKeywordsLoading(false)
                                        }
                                    }
                                )
                            }
                        })
                        .then(() => {
                            setNewKeywordSubmitterLoading(false)
                        })
                        .catch((err) => {
                            toast.error(err.message)
                            setKeywordsLoading(false)
                            setNewKeywordSubmitterLoading(false)
                        })
                })
        }
    }

    interface keywordObjData {
        url: string
        slug: string
    }

    const [keywordData, setKeywordData] = useState<keywordObjData>({
        url: "",
        slug: ""
    })

    useEffect(() => {
        if (store) {
            setKeywordData({
                url: store.url,
                slug: store.slug
            })
            // fetchSubStores()
        }
    }, [store])

    const handleKeywordEdit = async (keyword: keyword) => {
        setKeywords(
            keywords.map((item: keyword) => {
                if (item.keyword === keyword.keyword) {
                    return {
                        ...item,
                        isActive: !item.isActive
                    }
                }
                return item
            })
        )

        if (keyword.isActive) {
            await removeKeywordStore(keywordData.slug, keyword.keyword).then(
                (response) => {
                    if (response.status === 200 && response.data.success) {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message ?? "Something went wrong")
                    }
                }
            )
        } else {
            await createKeywordStores(
                keywordData.slug,
                keyword.keyword,
                keyword.keyword
            ).then((response) => {
                if (response.status === 200 && response.data.success) {
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message ?? "Something went wrong")
                }
            })
        }
    }

    const handleOldKeywordFiltering = (keywords: any[]) => {
        setKeywords([])

        let allKeywordArray: string | any[] = []

        for (const allKeywordArrayElement of keywords) {
            allKeywordArray = allKeywordArray.concat(allKeywordArrayElement)
        }

        keywordFilterHandler(
            allKeywordArray.map((keyword: any) => slugifyWord(keyword))
        )
    }

    const keywordFilterHandler = (allKeywordArray: string[]) => {
        setKeywords(
            keywords.map((keyword) => {
                return {
                    ...keyword,
                    notFound: !allKeywordArray.includes(slugifyWord(keyword.keyword))
                }
            })
        )
    }

    const [autoKeywordsLoading, setAutoKeywordsLoading] = useState<boolean>(false)
    const autoGenerateKeywordsHandler = async () => {
        setAutoKeywordsLoading(true)
        if (store) {
            setKeywordsLoading(true)
            autoGenerateKeywords([store.slug])
                .then((response) => {
                    if (response.success) {
                        toast.success("Auto keyword fetch Job Started")
                    }
                })
                .then(async () => {
                    await getStoreSlugs()
                })
                .catch((err) => {
                    if (err.response.errors) {
                        setAutoKeywordsLoading(false)
                        toast.error(err.response.errors[0].message)
                    }
                })
        }
    }

    const getStoreSlugs = () => {
        if (store) {
            getKeywordStoresBySlug(store.slug).then((response: string[]) => {
                if (response) {
                    setKeywords(
                        response.map((keyword) => ({
                            keyword: keyword,
                            isActive: true,
                            count: 1,
                            groupedKeywords: []
                        }))
                    )
                    setKeywordsLoading(false)
                    setAutoKeywordsLoading(false)
                }
            })
        }
    }

    const [subStores, setSubStores] = useState<subStore[]>([])
    const [locationStores, setLocationStores] = useState<Location[]>([])
    const [locationStoresLoading, setLocationStoresLoading] =
        useState<boolean>(true)
    const [newlyAddedLocations, setNewlyAddedLocations] = useState<string[]>([])

    // const fetchSubStores = async () => {
    //   if (store && store.slug) {
    //     const response = await getLocations(store.slug)
    //
    //     setLocationStores(response.data)
    //   }
    // }

    useEffect(() => {
        if (locationStores.length > 0) {
            const subStores: subStore[] = locationStores.map((location: any) => {
                return {
                    id: location.slug,
                    address: location.address,
                    addressUrl: location.url,
                    nameOfPlace: location.name
                }
            })

            setSubStores(subStores)
            setNewlyAddedLocations(subStores.map((subStore) => subStore.id))
        }
        setLocationStoresLoading(false)
    }, [locationStores])

    useEffect(() => {
        if (!locationStoresLoading) {
            const newlyAddedSubStore = subStores.filter((subStore: subStore) => {
                return newlyAddedLocations.indexOf(subStore.id) === -1
            })

            if (newlyAddedSubStore.length > 0) {
                AddSubStore(
                    newlyAddedSubStore[0].nameOfPlace,
                    newlyAddedSubStore[0].id,
                    newlyAddedSubStore[0].addressUrl,
                    newlyAddedSubStore[0].address,
                    store?.slug!
                )
                    .then((response) => {
                        if (response.success) {
                            toast.success("Sub Store Added Successfully")
                        } else {
                            toast.error(response.message)
                        }
                    })
                    .catch((err) => {
                        toast.error(err.response.errors[0].message)
                    })

                // here
                setNewlyAddedLocations([
                    ...newlyAddedLocations,
                    ...newlyAddedSubStore.map((subStore) => subStore.id)
                ])
            }
        }
    }, [locationStoresLoading, subStores])

    return (
        <div className="pt-6 pb-20">
            <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <div className="px-4 sm:px-6 lg:px-8">
                    <Breadcrumb
                        paths={[
                            {
                                name: "Stores",
                                route: "/stores"
                            },
                            {
                                name: store?.name!,
                                route: `/stores/edit/${store?.slug}`,
                                asLink: false
                            }
                        ]}
                    />
                    <div className="pb-8">
                        <nav className="flex items-center justify-between px-4 border-t border-gray-200 sm:px-0">
                            <div className="flex flex-1 w-0 -mt-px">
                                {previousStore && (
                                    <Link
                                        to={{ pathname: `/stores/edit/${previousStore.slug}` }}
                                        className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                                    >
                                        <ArrowNarrowLeftIcon
                                            className="w-5 h-5 mr-3 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        Previous
                                        <span className="ml-3 text-sm text-gray-400 ">
                      {previousStore.name}
                    </span>
                                    </Link>
                                )}
                            </div>

                            <div className="flex justify-end flex-1 w-0 -mt-px">
                                {nextStore && (
                                    <Link
                                        to={{ pathname: `/stores/edit/${nextStore.slug}` }}
                                        className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                                    >
                                        Next
                                        <ArrowNarrowRightIcon
                                            className="w-5 h-5 ml-3 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-3 text-sm text-gray-400 ">
                      {nextStore.name}
                    </span>
                                    </Link>
                                )}
                            </div>
                        </nav>
                    </div>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">
                                Edit Store - {store?.name}
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                You can edit store here
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center space-x-4">
                                <p className="text-sm">Inactive</p>
                                <Switch
                                    disabled={loading}
                                    checked={isActive}
                                    onChange={async (value) => {
                                        setIsActive(value)

                                        const success = await updateStore("active", value)

                                        if (success) {
                                            setIsActive(value)

                                            if (!value) {
                                                const removingPromises = []

                                                const removingCategories = assignedCategories.map(
                                                    async (category) => {
                                                        return new Promise((resolve, reject) => {
                                                            unAssignCategory(store?.slug!, category.slug)
                                                                .then(() => {
                                                                    resolve(true)
                                                                })
                                                                .catch((err) => {
                                                                    reject(err)
                                                                })
                                                        })
                                                    }
                                                )

                                                const removingKeywords = keywords.map(
                                                    async (keyword) => {
                                                        return new Promise((resolve, reject) => {
                                                            removeKeywordStore(store?.slug!, keyword.keyword)
                                                                .then(() => {
                                                                    resolve(true)
                                                                })
                                                                .catch((err) => {
                                                                    reject(err)
                                                                })
                                                        })
                                                    }
                                                )

                                                removingPromises.push(...removingCategories)
                                                removingPromises.push(...removingKeywords)

                                                Promise.all(removingPromises).then(() => {
                                                    setTrigger(!trigger)
                                                })
                                            }
                                        } else {
                                            setIsActive(!value)
                                        }
                                    }}
                                    className={classNames(
                                        isActive ? "bg-green-500" : "bg-red-500",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            isActive ? "translate-x-5" : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                    />
                                </Switch>
                                <p className="text-sm">Active</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mt-8">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                                <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                                    <InputEdit
                                        refetch={(slug) => fetchStore(slug)}
                                        fieldName={"name"}
                                        store={store!}
                                        type={"text"}
                                        label={"Store Name"}
                                    />
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="slug"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Slug
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <div className="flex items-center space-x-3 sm:mt-px sm:pt-2">
                                                <div className="text-sm">{store?.slug?.toString()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <InputEdit
                                        refetch={(slug) => fetchStore(slug)}
                                        fieldName={"alterText"}
                                        store={store!}
                                        type={"text"}
                                        label={"Alter Text"}
                                    />
                                    <InputEdit
                                        refetch={(slug) => fetchStore(slug)}
                                        fieldName={"cashback"}
                                        store={store!}
                                        type={"number"}
                                        label={"Cashback"}
                                    />
                                    <InputEdit
                                        refetch={(slug) => fetchStore(slug)}
                                        fieldName={"merchantID"}
                                        store={store!}
                                        type={"text"}
                                        label={"Merchant ID"}
                                    />

                                    <InputEdit
                                        required={false}
                                        refetch={(slug) => fetchStore(slug)}
                                        fieldName={"url"}
                                        store={store!}
                                        type={"text"}
                                        label={"Store URL"}
                                    />

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="cover-photo"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Logo
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            {!logo.editable && logo.url ? (
                                                <div className="flex items-center space-x-4">
                                                    <div
                                                        className="min-w-[250px] max-w-[250px] mt-4 rounded-md overflow-hidden">
                                                        <img
                                                            className="object-contain min-w-[250px] w-[250px] h-[250px]"
                                                            src={`${
                                                                logo.editable
                                                                    ? logo.url
                                                                    : `${process.env.REACT_APP_STORES_ASSETS}/${logo.url}`
                                                            }`}
                                                        />
                                                    </div>

                                                    <button
                                                        onClick={() => {
                                                            setLogo({
                                                                editable: true,
                                                                value: null,
                                                                url: null
                                                            })
                                                        }}
                                                        className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 " />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <LogoDropZone
                                                        onDrop={(file) => {
                                                            setLogo({
                                                                editable: true,
                                                                value: file,
                                                                url: file["preview"]
                                                            })
                                                        }}
                                                    />
                                                    {logo.editable && logo.value && (
                                                        <div className="mt-4">
                                                            <button
                                                                onClick={logoUpload}
                                                                disabled={loading}
                                                                className="inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                            >
                                                                {loading && (
                                                                    <>
                                                                        <svg
                                                                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <circle
                                                                                className="opacity-25"
                                                                                cx={12}
                                                                                cy={12}
                                                                                r={10}
                                                                                stroke="currentColor"
                                                                                strokeWidth={4}
                                                                            />
                                                                            <path
                                                                                className="opacity-75"
                                                                                fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                            />
                                                                        </svg>
                                                                    </>
                                                                )}

                                                                <span>Update</span>
                                                            </button>
                                                            <button
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    setLogo({
                                                                        editable: false,
                                                                        value: null,
                                                                        url: store?.logo!
                                                                    })
                                                                }}
                                                                type="button"
                                                                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="cover-photo"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Cover Image
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            {!cover.editable && cover.url ? (
                                                <div className="flex items-center space-x-4">
                                                    <div
                                                        className="min-w-[300px] max-w-[300px] mt-4 rounded-md overflow-hidden">
                                                        <img
                                                            className="object-cover min-w-[300px] w-[300px] h-[300px]"
                                                            src={`${
                                                                cover.editable
                                                                    ? cover.url
                                                                    : `${process.env.REACT_APP_STORES_ASSETS}/${cover.url}`
                                                            }`}
                                                        />
                                                    </div>

                                                    <button
                                                        onClick={() => {
                                                            setCover({
                                                                editable: true,
                                                                value: null,
                                                                url: null
                                                            })
                                                        }}
                                                        className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 " />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <CoverImageDropZone
                                                        onDrop={(file) => {
                                                            setCover({
                                                                editable: true,
                                                                value: file,
                                                                url: file["preview"]
                                                            })
                                                        }}
                                                    />
                                                    {cover.editable && cover.value && (
                                                        <div className="mt-4">
                                                            <button
                                                                onClick={coverUpload}
                                                                disabled={loading}
                                                                className="inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                            >
                                                                {loading && (
                                                                    <>
                                                                        <svg
                                                                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <circle
                                                                                className="opacity-25"
                                                                                cx={12}
                                                                                cy={12}
                                                                                r={10}
                                                                                stroke="currentColor"
                                                                                strokeWidth={4}
                                                                            />
                                                                            <path
                                                                                className="opacity-75"
                                                                                fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                            />
                                                                        </svg>
                                                                    </>
                                                                )}

                                                                <span>Update</span>
                                                            </button>
                                                            <button
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    setCover({
                                                                        editable: false,
                                                                        value: null,
                                                                        url: store?.cover!
                                                                    })
                                                                }}
                                                                type="button"
                                                                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="cover-photo"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Cover with logo
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            {!coverWithLogo.editable && coverWithLogo.url ? (
                                                <div className="flex items-center space-x-4">
                                                    <div
                                                        className="min-w-[300px] max-w-[300px] mt-4 rounded-md overflow-hidden">
                                                        <img
                                                            className="object-cover min-w-[300px] w-[300px] h-[300px]"
                                                            src={`${
                                                                coverWithLogo.editable
                                                                    ? coverWithLogo.url
                                                                    : `${process.env.REACT_APP_STORES_ASSETS}/${coverWithLogo.url}`
                                                            }`}
                                                            alt={"cover-with-logo"}
                                                        />
                                                    </div>

                                                    <button
                                                        onClick={() => {
                                                            setCoverWithLogo({
                                                                editable: true,
                                                                value: null,
                                                                url: null
                                                            })
                                                        }}
                                                        className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 " />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <CoverImageDropZone
                                                        onDrop={(file) => {
                                                            setCoverWithLogo({
                                                                editable: true,
                                                                value: file,
                                                                url: file["preview"]
                                                            })
                                                        }}
                                                    />
                                                    {coverWithLogo.editable && coverWithLogo.value && (
                                                        <div className="mt-4">
                                                            <button
                                                                onClick={uploadCoverWithLogo}
                                                                disabled={loading}
                                                                className="inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                                                            >
                                                                {loading && (
                                                                    <>
                                                                        <svg
                                                                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <circle
                                                                                className="opacity-25"
                                                                                cx={12}
                                                                                cy={12}
                                                                                r={10}
                                                                                stroke="currentColor"
                                                                                strokeWidth={4}
                                                                            />
                                                                            <path
                                                                                className="opacity-75"
                                                                                fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                            />
                                                                        </svg>
                                                                    </>
                                                                )}

                                                                <span>Update</span>
                                                            </button>
                                                            <button
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    setCoverWithLogo({
                                                                        editable: false,
                                                                        value: null,
                                                                        url: store?.coverWithLogo!
                                                                    })
                                                                }}
                                                                type="button"
                                                                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                    htmlFor="slug"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Logo Light
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center space-x-4">
                                        <p>Dark</p>
                                        <Switch
                                            disabled={loading}
                                            checked={isLogoLight}
                                            onChange={async (value) => {
                                                setIsLogoLight(value)

                                                const success = await updateStore("isLogoLight", value)

                                                if (success) {
                                                    setIsLogoLight(value)
                                                } else {
                                                    setIsLogoLight(!value)
                                                }
                                            }}
                                            className={classNames(
                                                isLogoLight ? "bg-primary-teal" : "bg-gray-200",
                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    isLogoLight ? "translate-x-5" : "translate-x-0",
                                                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                )}
                                            />
                                        </Switch>
                                        <p>Light</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                    htmlFor="inStoreAndOnline"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Store Availability
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                                        {storeAvailability && (
                                            <Select
                                                defaultValue={storeAvailability}
                                                onChange={async (value) => {
                                                    setStoreAvailability(value)

                                                    const success = await updateStore(
                                                        "storeType",
                                                        value.value
                                                    )

                                                    const success2 = await updateStore(
                                                        "storeAvailability",
                                                        value.label
                                                    )

                                                    if (success2) {
                                                        setStoreAvailability(value)
                                                    } else {
                                                        setStoreAvailability(!value)
                                                    }
                                                }}
                                                options={availability}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <InputEdit
                                required={false}
                                refetch={(slug) => fetchStore(slug)}
                                fieldName={"promotionLabel"}
                                store={store!}
                                type={"text"}
                                label={"Promotion Label"}
                                postOnClicked={async (value) => {
                                    if (value) {
                                        if (
                                            !assignedCategories
                                                .map((c) => c.slug)
                                                .includes("sales-and-offers")
                                        ) {
                                            assignCategory(store?.slug!, "sales-and-offers").then(
                                                () => {
                                                    refetchAssignedCategories(store?.slug!)
                                                }
                                            )
                                        }
                                    } else {
                                        await unAssignCategory(store?.slug!, "sales-and-offers")
                                    }
                                }}
                            />

                            {store?.promotionLabel && (
                                <InputEdit
                                    required={false}
                                    refetch={(slug) => fetchStore(slug)}
                                    fieldName={"promotionDescription"}
                                    store={store!}
                                    type={"text"}
                                    label={"Promotion Description"}
                                    postOnClicked={async (value) => {
                                        if (value) {
                                            if (
                                                !assignedCategories
                                                    .map((c) => c.slug)
                                                    .includes("sales-and-offers")
                                            ) {
                                                assignCategory(store?.slug!, "sales-and-offers").then(
                                                    () => {
                                                        refetchAssignedCategories(store?.slug!)
                                                    }
                                                )
                                            }
                                        }
                                    }}
                                />
                            )}

                            <InputEdit
                                refetch={(slug) => fetchStore(slug)}
                                fieldName={"description"}
                                store={store!}
                                textarea
                                type={"text"}
                                label={"Description"}
                                maxLength={200}
                                required={false}
                            />

                            {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
                            {/*  <label*/}
                            {/*    htmlFor="buttonText"*/}
                            {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"*/}
                            {/*  >*/}
                            {/*    Branches*/}
                            {/*  </label>*/}
                            {/*  <div className="mt-1 sm:mt-0 sm:col-span-2 rounded-md sm:max-w-lg">*/}
                            {/*    <StoreLocationCard*/}
                            {/*      editable={true}*/}
                            {/*      storeSlug={store?.slug!}*/}
                            {/*      subStores={subStores}*/}
                            {/*      setSubStores={setSubStores}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Categories
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        {store?.slug && (
                                            <AssignCategory
                                                refetch={(categories) => {
                                                    setAssignedCategories(categories)
                                                }}
                                                storeSlug={store.slug}
                                            />
                                        )}

                                        <div className="flex flex-wrap w-full pt-4 pb-4 gap-2">
                                            {assignedCategories.map((item) => {
                                                return (
                                                    <motion.div
                                                        initial={{
                                                            opacity: 0,
                                                            scale: 0.5
                                                        }}
                                                        animate={{
                                                            opacity: 1,
                                                            scale: 1
                                                        }}
                                                        exit={{
                                                            opacity: 0,
                                                            scale: 0.5
                                                        }}
                                                        key={item.slug}
                                                        className="flex items-center px-4 py-3 space-x-4 rounded-full bg-primary-blue "
                                                    >
                                                        <div className="text-sm text-white">
                                                            {item.name}
                                                        </div>
                                                        <button
                                                            onClick={async () => {
                                                                await unAssignCategory(store?.slug!, item.slug)

                                                                const ifRemovingSalesAndOffers =
                                                                    item.name?.toLowerCase().includes("sale") ||
                                                                    item.name?.toLowerCase().includes("offer") ||
                                                                    item.name
                                                                        ?.toLowerCase()
                                                                        .includes("promotion") ||
                                                                    item.name
                                                                        ?.toLowerCase()
                                                                        .includes("discount") ||
                                                                    item.name?.toLowerCase().includes("deal") ||
                                                                    item.name
                                                                        ?.toLowerCase()
                                                                        .includes("clearance") ||
                                                                    item.name?.toLowerCase().includes("offer")

                                                                if (ifRemovingSalesAndOffers) {
                                                                    await editStore(store?.slug!, {
                                                                        promotionLabel: ""
                                                                    }).then(() => {
                                                                        setStore((prev) => ({
                                                                            ...prev!,
                                                                            promotionLabel: ""
                                                                        }))
                                                                    })
                                                                }
                                                            }}
                                                            className="flex items-center justify-center w-5 h-5 rounded-full"
                                                        >
                                                            <PillCloseIcon />
                                                        </button>
                                                    </motion.div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="lg:grid lg:grid-cols-3 lg:gap-4 lg:items-start md:border-t md:border-gray-200 md:pt-5">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Location
                                    </label>
                                    <div className="mt-1 lg:mt-0 lg:col-span-2">
                                        <div className="flex items-center space-x-3">
                                            <AddLocationStore
                                                onAddLocation={(location) => {
                                                    if (addedLocations.length > 10) {
                                                        return toast.error("You can’t exceed 10 locations")
                                                    }
                                                    if (
                                                        addedLocations.some(
                                                            (item) => item.id === location.id
                                                        )
                                                    ) {
                                                        return toast.error("Location added already!")
                                                    }
                                                    handleLocationActive(location, true)
                                                }}
                                                locationsList={locations} />
                                            <PrimaryButton
                                                onClick={() => setShowAddNewLocationForm(!showAddNewLocationForm)}
                                                title="Add Location"
                                            ></PrimaryButton>
                                            {addedLocations.length > 0 &&
                                                <Link to={`/stores/edit/${store?.slug}/locations`}>
                                                    <PrimaryButton
                                                        title="list Location"
                                                    />
                                                </Link>
                                            }
                                        </div>

                                        {locationsLoading ?
                                            <div className="flex items-center px-4 py-3 space-x-4 w-full">
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <Skeleton
                                                        key={index}
                                                        borderRadius={100}
                                                        height={36}
                                                        width={100}
                                                        className="flex items-center px-4 py-2 space-x-4 rounded-full"
                                                    />
                                                ))}
                                            </div> :
                                            <>{addedLocations.length > 0 &&
                                                <div className="flex flex-wrap w-full pt-4 pb-4 gap-2">
                                                    {addedLocations.map((item) => {
                                                        return (
                                                            <motion.div
                                                                initial={{
                                                                    opacity: 0,
                                                                    scale: 0.5
                                                                }}
                                                                animate={{
                                                                    opacity: 1,
                                                                    scale: 1
                                                                }}
                                                                exit={{
                                                                    opacity: 0,
                                                                    scale: 0.5
                                                                }}
                                                                key={item.id}
                                                                className="flex items-center px-4 py-3 space-x-4 rounded-full bg-primary-blue "
                                                            >
                                                                <div className="text-sm text-white">
                                                                    {item.nameOfPlace}
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleRemoveLocationForStore(item)}
                                                                    className="flex items-center justify-center w-5 h-5"
                                                                >
                                                                    <PillCloseIcon />
                                                                </button>
                                                            </motion.div>
                                                        )
                                                    })}
                                                </div>}</>}
                                    </div>
                                    <div className={"mt-4 lg:mt-0 sm:col-span-3"}>
                                        <motion.div initial={"closed"}
                                                    variants={{ opened: { height: "auto" }, closed: { height: 0 } }}
                                                    animate={showAddNewLocationForm ? "opened" : "closed"}
                                                    transition={{ bounce: 0, duration: 2.3 }}
                                                    className={"overflow-y-hidden"}>
                                            <AddNewLocationForm
                                                onSave={(values) => {
                                                    AddLocations(store?.slug, values, false)
                                                }}
                                                showAddNewLocationForm={showAddNewLocationForm}
                                                setShowAddNewLocationForm={setShowAddNewLocationForm}
                                                locationList={locations} />
                                        </motion.div>
                                    </div>
                                </div>
                            </div>

                            <div className={`hidden`}>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                    <label
                                        htmlFor="keywords"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Keywords (optional)
                                    </label>
                                    <div
                                        className={`flex flex-col mt-1 sm:mt-0 sm:col-span-2 sm:grid sm:max-w-lg`}
                                    >
                                        <div className={`flex flex-wrap w-full pt-4 pb-4`}>
                                            {store && store.slug && keywords.length === 0 ? (
                                                <>
                                                    <p className="mt-2 text-sm text-gray-500">
                                                        {keywordsLoading
                                                            ? "Loading keywords"
                                                            : "No keywords assigned"}
                                                    </p>
                                                </>
                                            ) : (
                                                keywords.map((keyword, index) => {
                                                    if (
                                                        keyword.keyword.toString() !==
                                                        store?.slug?.toString()
                                                    ) {
                                                        return (
                                                            <KeywordPill
                                                                key={index}
                                                                keyword={keyword}
                                                                onClickHandler={handleKeywordEdit}
                                                                notFound={keyword.notFound}
                                                            />
                                                        )
                                                    }
                                                })
                                            )}
                                        </div>
                                        <div className={`sm:border-t sm:border-gray-200 sm:pt-5`}>
                                            <Keywords
                                                loading={newKeywordSubmitterLoading}
                                                submittable={true}
                                                keywordData={keywordData}
                                                alreadySelectedKeywords={keywords}
                                                exportSelectedKeywordsArray={handleKeywordsSelection}
                                                exportFetchedKeywords={handleOldKeywordFiltering}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                <label
                                    htmlFor="keywords"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Auto Keywords
                                </label>
                                <div
                                    className={`flex flex-col mt-1 sm:mt-0 sm:col-span-2 grid sm:max-w-lg`}
                                >
                                    <div className={`flex flex-wrap w-full pt-4 pb-4`}>
                                        {store && store.slug && keywords.length === 0 ? (
                                            <>
                                                <p className="mt-2 text-sm text-gray-500">
                                                    {keywordsLoading
                                                        ? "Loading keywords"
                                                        : "No keywords assigned"}
                                                </p>
                                            </>
                                        ) : (
                                            keywords.map((keyword, index) => {
                                                if (
                                                    keyword.keyword.toString() !== store?.slug?.toString()
                                                ) {
                                                    return (
                                                        <KeywordPill
                                                            key={index}
                                                            keyword={keyword}
                                                            onClickHandler={handleKeywordEdit}
                                                            notFound={keyword.notFound}
                                                        />
                                                    )
                                                }
                                            })
                                        )}
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            onClick={autoGenerateKeywordsHandler}
                                            disabled={autoKeywordsLoading}
                                            loading={autoKeywordsLoading}
                                            type="button"
                                            title={"Auto Assign Keywords"}
                                        ></PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5">
                            {/* <div className="flex justify-end space-x-3">
                <button
                  disabled={loading}
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                >
                  Cancel
                </button>
                <PrimaryButton
                  onClick={onSubmit}
                  disabled={loading}
                  loading={loading}
                  title={"Submit"}
                ></PrimaryButton>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProtected(EditStore)
