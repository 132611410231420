import withProtected from "../../../core/hoc/withProtected"
import React, { useEffect, useState } from "react"
import {
    deleteBanner,
    deleteBannerPlace,
    editBannerPlace,
    getAllBanner,
    getAllBannerPlaces
} from "../../../data/banner_data"
import { CenteredContainer } from "../../../core/layouts"
import { Link, useNavigate } from "react-router-dom"
import {
    ChevronRightIcon,
    PlusIcon,
    BookmarkAltIcon,
    BookmarkIcon
} from "@heroicons/react/outline"
import { Banner, BannerPlace } from "../../../types"
import PrimaryButton from "../../../core/components/Button"
import toast from "react-hot-toast"
import BannerModalPreview from "../components/BannerModalPreview"
import PlaceSwiper from "../components/PlaceSwiper"
import { getStoreBannerPlaces, placeBanner } from "../../../data/banners_new_data"

const ListBanners = () => {
    const navigate = useNavigate()

    const [banners, setBanners] = useState<Banner[]>([])
    const [bannerPlacements, setBannerPlacements] = useState<BannerPlace[]>([])

    const fetchBanners = async () => {
        getAllBanner().then((res) => {
            if (res.data) {
                setBanners(res.data.banners.reverse())
            }
        })
    }

    const fetchBannerPlacements = async () => {
        // getAllBannerPlaces().then((res) => {
        //   if (res.data) {
        //     setBannerPlacements(res.data)
        //   }
        // })

        getStoreBannerPlaces().then((res) => {
            if (res.data) {
                setBannerPlacements(res.data)
            }
        })
    }

    useEffect(() => {
        fetchBanners()
        fetchBannerPlacements()
    }, [])

    const [openBannerModal, setOpenBannerModal] = useState(false)
    const [bannerData, setBannerData] = useState<Banner | null>(null)
    const openBannerModalHandler = (bannerData: Banner) => {
        setBannerData(bannerData)
        setOpenBannerModal(true)
    }

    const deleteBannerItem = (slug: string) => {
        deleteBanner(slug)
            .then((res) => {
                toast.success(res.message)
            })
            .then(() => {
                fetchBanners()
                    .then(() => {
                        setOpenBannerModal(false)
                        setBannerData(null)
                    })
                    .then(() => {
                        fetchBannerPlacements()
                    })
            })
    }

    const sendToEditPlacePage = async (placeData: BannerPlace) => {
        navigate(`/banners/edit-place/${placeData.page}/${placeData.place}`)
    }

    const deleteBannerPlaceHandler = (page: string, place: string) => {
        deleteBannerPlace({
            page,
            place
        }).then(() => {
            fetchBannerPlacements()
        })
    }

    const [placeState, setPlaceState] = useState<boolean>(false)
    const [placeStateSlug, setPlaceStateSlug] = useState<string>("")

    const setPlaceStateSlugHandler = (slug: string, state: boolean) => {
        setPlaceStateSlug(slug)
        setPlaceState(state)
    }

    const addBannerToBannerPlaceHandler = (
        slug: string,
        bannerPlaceSlugs: string[],
        page: string,
        place: string
    ) => {
        const ifBannerPlaceExists = bannerPlaceSlugs.find((bannerPlaceSlug) => {
            return bannerPlaceSlug === slug
        })

        if (ifBannerPlaceExists) {
            return toast.error("Banner already exists in this place")
        }

        placeBanner(place, page, slug).then(() => {
            fetchBannerPlacements()
            setPlaceStateSlugHandler("", false)
            toast.success("Banner Place Edited Successfully")
        })
    }

    return (
        <CenteredContainer
            title="Banners and Banner Placements"
            subtitle="You can view banners and  banner placements here"
            paths={[
                {
                    name: "Banner",
                    route: "/banner",
                    asLink: true
                }
            ]}
            actions={
                <div className={`flex flex-row space-x-2`}>
                    <Link to={"/banners/add-place"}>
                        <PrimaryButton
                            title="Add Place"
                            icon={<PlusIcon className="w-5 h-5" />}
                        />
                    </Link>
                    <Link to={"/banners/add"}>
                        <PrimaryButton
                            title="Add Banner"
                            icon={<PlusIcon className="w-5 h-5" />}
                        />
                    </Link>
                </div>
            }
        >
            <main className="flex-1 pb-8">
                <div className="mt-8">
                    <div className="mx-auto max-w-6xl">
                        <BannerModalPreview
                            deleteBannerItem={deleteBannerItem}
                            isOpen={openBannerModal}
                            setIsOpen={setOpenBannerModal}
                            data={bannerData}
                        />

                        <h2 className="text-xl font-medium leading-6 text-gray-900">
                            Places
                        </h2>
                        {bannerPlacements.length === 0 ? (
                            <>
                                <button
                                    type="button"
                                    onClick={() => {
                                        navigate("/banners/add-place")
                                    }}
                                    className="mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <BookmarkAltIcon className="mx-auto h-12 w-12 text-gray-400" />
                                    <span className="mt-2 block text-sm font-medium text-gray-900">
                                        Add a new Banner Placement
                                    </span>
                                </button>
                            </>
                        ) : (
                            <div className="mt-4 w-full">
                                {/* Card */}
                                <PlaceSwiper
                                    placeState={placeState}
                                    placeStateSlug={placeStateSlug}
                                    deleteBannerPlaceHandler={deleteBannerPlaceHandler}
                                    sendToEditPlacePage={sendToEditPlacePage}
                                    places={bannerPlacements}
                                    addBannerToBannerPlaceHandler={addBannerToBannerPlaceHandler}
                                    bannerData={bannerData!}
                                />
                            </div>
                        )}
                    </div>

                    <h2 className="mx-auto mt-8 mb-4 max-w-6xl text-xl font-medium leading-6 text-gray-900">
                        Banners
                    </h2>

                    {banners.length === 0 ? (
                        <div className={`mx-auto max-w-6xl`}>
                            <button
                                type="button"
                                onClick={() => {
                                    navigate("/banners/add")
                                }}
                                className=" mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <BookmarkIcon className="mx-auto h-12 w-12 text-gray-400" />
                                <span className="mt-2 block text-sm font-medium text-gray-900">
                                    Add a new Banner
                                </span>
                            </button>
                        </div>
                    ) : (
                        <>
                            {/* Activity list (the smallest breakpoint only) */}
                            <div className="shadow sm:hidden">
                                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                    {banners.map((singleBanner: Banner) => (
                                        <li
                                            onClick={() => openBannerModalHandler(singleBanner)}
                                            key={singleBanner.slug}
                                        >
                                            <div
                                                style={{
                                                    backgroundImage: `url(${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail})`
                                                }}
                                                className="block bg-white px-4 py-4 hover:bg-gray-50"
                                            >
                                                <span className="flex items-center space-x-4">
                                                    <span className="flex flex-1 space-x-2 truncate">
                                                           <span
                                                               className="flex flex-col truncate text-sm text-gray-500">
                                                               <div className="flex items-center">
                                                                   <div className="h-10 w-auto flex-shrink-0">
                                                                       <img
                                                                           className="h-10 w-auto "
                                                                           src={`${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail}`}
                                                                           alt=""
                                                                       />
                                                             </div>
                                                           </div>
                                                         </span>
                                                       </span>
                                                    {placeState && placeStateSlug === singleBanner.slug ? (
                                                        <>
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setPlaceStateSlugHandler("", false)
                                                                }}
                                                            >
                                                                Cancel
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span
                                                                className={`relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6`}
                                                            >
                                                               <span
                                                                   onClick={(e) => {
                                                                       e.stopPropagation()
                                                                       setPlaceStateSlugHandler(
                                                                           singleBanner.slug,
                                                                           true
                                                                       )
                                                                       setBannerData(singleBanner)
                                                                   }}
                                                                   className={`${singleBanner.textColor === '#001C33' ?  'text-walls-of-santorini' : 'text-gray-900' } mr-3 cursor-pointer`}
                                                               >
                                                                 Place
                                                               </span>
                                                             </span>
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    navigate(`/banners/edit/${singleBanner.slug}`)
                                                                }}
                                                            >
                                                                <ChevronRightIcon
                                                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Activity table (small breakpoint and up) */}
                            <div className="hidden sm:block">
                                <div className="mx-auto max-w-6xl">
                                    <div className="mt-2 flex flex-col">
                                        <div
                                            className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Thumbnail
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    ></th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    ></th>
                                                </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                {banners.map((singleBanner) => (
                                                    <tr
                                                        onClick={() =>
                                                            openBannerModalHandler(singleBanner)
                                                        }
                                                        key={singleBanner.slug}
                                                        className="bg-white "
                                                        style={{
                                                            backgroundImage: `url(${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail})`
                                                        }}
                                                    >
                                                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            <div className="flex">
                                                                <div className="flex items-center">
                                                                    <div className="h-10 w-auto flex-shrink-0">
                                                                        <img
                                                                            className="h-10 w-auto "
                                                                            src={`${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail}`}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {placeState &&
                                                        placeStateSlug === singleBanner.slug ? (
                                                            <>
                                                                <td></td>
                                                                <td className="whitespace-nowrap pr-6 py-4 text-right text-sm font-medium text-gray-500">
                                                                    <div
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setPlaceStateSlugHandler("", false)
                                                                        }}
                                                                        className={`flex flex-row items-center justify-end cursor-pointer`}
                                                                    >
                                                                        <span
                                                                            className={`text-primary-blue mr-3 cursor-pointer`}
                                                                        >
                                                                            Cancel
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                    <span
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setPlaceStateSlugHandler(
                                                                                singleBanner.slug,
                                                                                true
                                                                            )
                                                                            setBannerData(singleBanner)
                                                                        }}
                                                                        className="text-primary-blue mr-3 cursor-pointer"
                                                                    >
                                                                        Place
                                                                    </span>
                                                                </td>
                                                                <td className="whitespace-nowrap pr-6 py-4 text-right text-sm font-medium text-gray-500">
                                                                    <div
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            navigate(
                                                                                `/banners/edit/${singleBanner.slug}`
                                                                            )
                                                                        }}
                                                                        className={`flex flex-row items-center justify-end cursor-pointer`}
                                                                    >
                                                                        <span
                                                                            className={`text-primary-blue mr-3 cursor-pointer`}
                                                                        >
                                                                            Edit
                                                                        </span>
                                                                        <ChevronRightIcon
                                                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </main>
        </CenteredContainer>
    )
}

export default withProtected(ListBanners)
