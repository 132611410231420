import axios from 'axios';
import { Auth } from "aws-amplify"


// Create an Axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
instance.interceptors.request.use(async (config) => {
  // Get Request Type (GET, POST, PUT, DELETE)
  const requestType = config.method;
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  config.headers.Authorization =token

  return config;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // console.log('url : ', response.config.url);
    // console.log('BEFORE DECRYPT : ', response.data);


    // console.log('AFTER DECRYPT : ', response.data);

    return response;
  },
  (error) => {

    // Handle the error
    return Promise.reject(error);
  },
);

export default instance;